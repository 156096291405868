"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uuid = exports.status = exports.practitionerResourceType = exports.practitionerParticipants = exports.organizationResourceType = exports.organizationParticipants = exports.name = exports.managingOrganizations = exports.id = exports.groupResourceType = exports.careTeamResourceType = exports.URL_EDIT_CARE_TEAM = exports.URL_CREATE_CARE_TEAM = exports.URL_CARE_TEAM = exports.URL_ADMIN = exports.SEARCH_QUERY_PARAM = exports.ROUTE_PARAM_CARE_TEAM_ID = exports.FHIR_PRACTITIONERS = exports.FHIR_GROUPS = exports.FHIR_CARE_TEAM = void 0;
var SEARCH_QUERY_PARAM = 'searchQuery';
exports.SEARCH_QUERY_PARAM = SEARCH_QUERY_PARAM;
var careTeamResourceType = 'CareTeam';
exports.careTeamResourceType = careTeamResourceType;
var groupResourceType = 'Group';
exports.groupResourceType = groupResourceType;
var practitionerResourceType = 'Practitioner';
exports.practitionerResourceType = practitionerResourceType;
var organizationResourceType = 'Organization';
exports.organizationResourceType = organizationResourceType;
var URL_ADMIN = '/admin';
exports.URL_ADMIN = URL_ADMIN;
var FHIR_CARE_TEAM = 'CareTeam';
exports.FHIR_CARE_TEAM = FHIR_CARE_TEAM;
var FHIR_GROUPS = 'Group';
exports.FHIR_GROUPS = FHIR_GROUPS;
var FHIR_PRACTITIONERS = 'Practitioner';
exports.FHIR_PRACTITIONERS = FHIR_PRACTITIONERS;
var URL_EDIT_CARE_TEAM = "".concat(URL_ADMIN, "/CareTeam/edit");
exports.URL_EDIT_CARE_TEAM = URL_EDIT_CARE_TEAM;
var URL_CREATE_CARE_TEAM = "".concat(URL_ADMIN, "/CareTeam/new");
exports.URL_CREATE_CARE_TEAM = URL_CREATE_CARE_TEAM;
var URL_CARE_TEAM = "".concat(URL_ADMIN, "/CareTeams");
exports.URL_CARE_TEAM = URL_CARE_TEAM;
var ROUTE_PARAM_CARE_TEAM_ID = 'careTeamId';
exports.ROUTE_PARAM_CARE_TEAM_ID = ROUTE_PARAM_CARE_TEAM_ID;
var uuid = 'uuid';
exports.uuid = uuid;
var id = 'id';
exports.id = id;
var name = 'name';
exports.name = name;
var status = 'status';
exports.status = status;
var practitionerParticipants = 'practitionerParticipants';
exports.practitionerParticipants = practitionerParticipants;
var organizationParticipants = 'organizationParticipants';
exports.organizationParticipants = organizationParticipants;
var managingOrganizations = 'managingOrganizations';
exports.managingOrganizations = managingOrganizations;