"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LocationUnitStatus = void 0;
var LocationUnitStatus;
exports.LocationUnitStatus = LocationUnitStatus;

(function (LocationUnitStatus) {
  LocationUnitStatus["ACTIVE"] = "active";
  LocationUnitStatus["INACTIVE"] = "inactive";
  LocationUnitStatus["SUSPENDED"] = "suspended";
})(LocationUnitStatus || (exports.LocationUnitStatus = LocationUnitStatus = {}));